@value vars: "./vars.module.css";
@value system-font, phone, tablet-min from vars;

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: system-font;
}

html {
  /* need for better work spy scroll in sidebars */
  scroll-padding-top: 30px;
  font-style: normal;

  /* Old colors */
  --color-text-white: #ffffff;
  --color-text-gray: #b4b4b4;
  --color-text-gray-content: #c5c5c5;
  --color-text-light-gray: #e6e6e6;
  --color-text-peach: #ffe2d1;
  --color-light-secondary: #E9E8FF;

  /* transition */
  --tran-duration-300: 300ms;
  --transition-toaster: 1s;

  /* spacing */
  --lspacing-11: -0.011px;
  --lspacing-p: -0.076px;
  --lspacing-banner: -0.32px;
  --lspacing-btn: -0.038px;
  --lspacing-h4: 0.082px;

  /* Header vars */
  --header-desktop-height: calc(61px + 20px);
  --header-mobile-height: calc(52px + 16px);

  --mobile-banner-top-padding: calc(var(--header-mobile-height) + 44px);

  /* desktop old pages */
  --header-desktop-height-for-old-pages: 88px;
}

@media (max-width: tablet-min) {
  html {
    overflow-x: hidden;
  }
}

html,
html[data-theme='light'] {
  --color-text: #202020;
  --color-block-light-1: #eae8ff;
  --color-block-light-2: #f4f4f4;
  --color-action: #5f58ff;
  --color-action-2: #5f58ff;
  --color-input-bg: #ebf0ff;
  --color-placeholder: #5f58ff;
  --color-header: #ff8058;
  --color-light-blue: #b9eefe;
  --color-white: #ffffff;
  --color-background: #ffffff;
  --color-background-secondary: #ffffff;
  --color-header-logo: #5f58ff;
  --color-underscore: #ff8058;
  --color-link: #5f58ff;
  --color-divider: #eae8ff;
  --color-litepaper-divider: #eae8ff;
  --color-theme-button-background: #ff8058;
  --color-theme-button-text: #f4f4f4;
  --color-theme-button-selected: #202020;
  --color-error: #f90021;
  --color-success: #00e205;
  --color-info: #3f8bef;
  --color-warning: #fe630c;
  --color-loading: #8d86eb;
  --color-border: #eae8ff20;
  --color-hover-card: #eae8ff;

  /* special cases */
  --color-text-secondary: #ffffff;
  --color-main-orange: #ff8058;
  --color-card-bg: #f4f4f4;
  --color-input-border: #eae8ff;
  --color-text-lign-bg: #666;

  /* header backgrounds */
  --image-validator: url('/public/assets/validator/validator.png');
  --image-utility-and-economics: url('/public/assets/utilityAndEconomics/utility-and-economics.png');
  --image-utility-and-economics-mobile: url('/public/assets/utilityAndEconomics/utility-and-economics-mobile.png');
  --image-media-kit: url('/public/assets/mediakit/media-kit.png');
  --image-about-us: url('/public/assets/aboutUs/banner-bg.png');
  --image-about-us-mobile: url('/public/assets/aboutUs/banner-bg-mobile.png');
  --image-grants: url('/public/assets/grants/grants.png');
  --image-foundation-delegations: url('/public/assets/foundationDelegations/foundationDelegations.svg');

  /* shadows */
  --shadow-primary: 0px 1px 1px rgba(0, 23, 50, 0.05), 0px 2px 4px rgba(0, 23, 50, 0.05),
    0px 7px 15px rgba(0, 23, 50, 0.05);
  --shadow-secondary: 0px 7px 15px 0px rgba(4, 8, 12, 0.19), 0px 2px 4px 0px rgba(1, 9, 18, 0.05),
    0px 1px 1px 0px rgba(0, 23, 50, 0.05);
}

html[data-theme='dark'] {
  --color-text: #f4f4f4;
  --color-block-light-1: #44494a;
  --color-block-light-2: #333333;
  --color-action: #5f58ff;
  --color-action-2: #b9eefe;
  --color-input-bg: #202020;
  --color-placeholder: #f4f4f4;
  --color-header: #b9eefe;
  --color-light-blue: #b9eefe;
  --color-white: #ffffff;
  --color-background: #010101;
  --color-background-secondary: #121212;
  --color-header-logo: #ffffff;
  --color-underscore: #f86412;
  --color-link: #f4f4f4;
  --color-divider: #b9eefe;
  --color-litepaper-divider: #333;
  --color-theme-button-background: #333333;
  --color-theme-button-text: #f4f4f4;
  --color-theme-button-selected: #202020;
  --color-error: #ff3023;
  --color-success: #00e205;
  --color-info: #3f8bef;
  --color-warning: #fe630c;
  --color-loading: #8d86eb;
  --color-border: #eae8ff33;
  --color-hover-card: #333;

  /* special cases */
  --color-text-secondary: #f4f4f4;
  --color-main-orange: #f86412;
  --color-card-bg: #202020;
  --color-input-border: rgba(255, 255, 255, 0.25);
  --color-text-lign-bg: #666;

  /* header backgrounds */
  --image-validator: url('/public/assets/validator/validator.png');
  --image-utility-and-economics: none;
  --image-utility-and-economics-mobile: none;
  --image-media-kit: url('/public/assets/mediakit/media-kit.png');
  --image-grants: url('/public/assets/grants/grants.png');
  --image-foundation-delegations: url('/public/assets/foundationDelegations/foundationDelegations.svg');

  /* shadows */
  --shadow-primary: 0px 1px 1px rgba(0, 23, 50, 0.05), 0px 2px 4px rgba(0, 23, 50, 0.05),
    0px 7px 15px rgba(0, 23, 50, 0.05);
  --shadow-secondary: 0px 7px 15px 0px rgba(4, 8, 12, 0.19), 0px 2px 4px 0px rgba(1, 9, 18, 0.05),
    0px 1px 1px 0px rgba(0, 23, 50, 0.05);

  /* redesign new colors */
  --color-neutral-light: #fff;
  --color-neutral-dark: #040404;

  /* header */
  --color-header-bg: #171717;

  /* grey */
  --color-grey-100: #e6e6e6;
  --color-grey-200: #cdcdcd;
  --color-grey-300: #b4b4b4;
  --color-grey-400: #9b9b9b;
  --color-grey-500: #828282;
  --color-grey-600: #686868;
  --color-grey-700: #4f4f4f;
  --color-grey-800: #363636;
  --color-grey-900: #1d1d1d;

  /* blue */
  --color-blue-50: #bfbcff;
  --color-blue-100: #9f9bff;
  --color-blue-200: #8f8aff;
  --color-blue-300: #7f79ff;
  --color-blue-400: #6f69ff;
  --color-blue-500: #5f58ff;
  --color-blue-600: #564fe6;
  --color-blue-700: #4c46cc;
  --color-blue-800: #433eb3;
  --color-blue-900: #393599;
  --color-blue-950: #262366;

  /* orange */
  --color-orange-50: #fcc1a0;
  --color-orange-100: #fba271;
  --color-orange-200: #fa9359;
  --color-orange-300: #f98341;
  --color-orange-400: #f9742a;
  --color-orange-500: #f86412;
  --color-orange-600: #df5a10;
  --color-orange-700: #c6500e;
  --color-orange-800: #ae460d;
  --color-orange-900: #953c0b;
  --color-orange-950: #632807;

  /* light-blue */
  --color-cyan-50: #c0f0fe;
  --color-cyan-100: #b9eefe;
  --color-cyan-200: #a7d6e5;
  --color-cyan-300: #94becb;
  --color-cyan-400: #82a7b2;
  --color-cyan-500: #6f8f98;
  --color-cyan-600: #5d777f;
  --color-cyan-700: #4a5f66;
  --color-cyan-800: #37474c;
  --color-cyan-900: #253033;
  --color-cyan-950: #121819;
}

body {
  font-weight: normal;
  position: relative;
  min-width: 320px;
  background-color: var(--color-background);
  color: var(--color-text);
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
  pointer-events: all;
}

a:hover {
  opacity: 1;
}

button {
  outline: none;
  border: none;
  background-color: transparent;
}

a,
button {
  transition: opacity var(--tran-duration-300) ease-in-out;
}

a:hover,
button:hover {
  opacity: 0.8;
  cursor: pointer;
}

section {
  width: 100%;
}

/* remove browser autofill bg */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-text-fill-color: var(--color-text);
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px #23232329;
}

.no-scroll {
  overflow: hidden;
}
