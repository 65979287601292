@value vars: 'styles/theme/vars.module.css';
@value tablet, phone from vars;

.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
}

.transiiton-off *,
.transiiton-off *::before,
.transiiton-off *::after {
  transition: none !important;
}

.scrollBlock::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: transparent;
}
.scrollBlock::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: transparent;
}
.scrollBlock::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: lightgray;
}

.header::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  width: 100%;

  background-repeat: no-repeat;
  background-size: cover;
}

.banner {
  position: absolute;
  top: -103px;
  left: calc((100vw - 1280px) / -2);

  width: 100vw;
  height: calc(100% + 102px);
  z-index: -1;
}

.banner img {
  width: 100%;
  height: 100%;

  object-fit: cover;
}

@media (max-width: 1280px) {
  .banner {
    left: 0;
  }
}
@media (max-width: 480px) {
  .banner {
    top: calc(var(--header-mobile-height) * -1);
    height: calc(100% + var(--header-mobile-height));
  }
}

/* icons */
.iconWrapperBox {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid var(--color-grey-700);
  display: flex;
  align-items: center;
  justify-content: center;
}
