@value vars: "styles/theme/vars.module.css";
@value phone, tablet, container from vars;

.footer {
  padding: 64px 0;
  display: flex;
  flex-direction: column;
  gap: 44px;
}

.footerBg {
  background-color: var(--color-neutral-dark);
}
.copyrightBlock {
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  height: 46px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.copyrightBlockText {
  opacity: 0.75;
}
.title,
.link {
  text-transform: capitalize;
}
.linkText {
  opacity: 0.8;
}

.footerNavBlock {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 187px;
}
.footerNavBlockValues {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.footerContent {
  display: flex;
  gap: 24px;
  min-height: 187px;
}

.footerInner {
  width: 100%;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}

.logo {
  display: flex;
  align-items: center;
  min-width: 292px;
  justify-content: space-between;
  row-gap: 30px;
  flex-wrap: wrap;
  height: fit-content;
}

.logoInner {
  cursor: pointer;
}

.logo > svg:last-child {
  margin-left: 24px;
}

.logoIcon {
  height: 32px;
  width: 32px;
  fill: var(--color-text);
}

.logoMavryk {
  height: 32px;
  width: 125px;
  margin-left: 15px;
  fill: var(--color-text);
}

@media (max-width: 1280px) {
  .footer {
    padding: 64px 16px 32px 16px;
  }
}
@media (max-width: tablet) {
  .footerContent {
    flex-direction: column;
    gap: 32px;
  }
  .footerInner {
    gap: 32px;
    flex-direction: column;
  }
  .logoIcon {
    display: none;
  }
  .logoMavryk {
    margin-left: 0;
  }
  .copyrightBlockText,
  .linkText {
    font-size: 14px;
  }
}

@media (max-width: phone) {
}
