@value vars: 'styles/theme/vars.module.css';
@value phone from vars;

.susPenseWrapper {
  position: fixed;
  z-index: 12;
  inset: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  font-weight: 600;
  font-size: 18px;
}

.susPenseWrapper::after {
  content: '';
  background-color: var(--color-background);
  position: absolute;
  z-index: -1;
  inset: 0;
}

.susPenseWrapper figcaption {
  margin-top: -30px;
}

.susPenseWrapper div {
  width: 500px;
  height: 400px;
}

@media (max-width: phone) {
  .susPenseWrapper div {
    width: 300px;
    height: 200px;
  }
}
