@value vars: "styles/theme/vars.module.css";
@value phone, tablet, container from vars;

.mobileOnly {
  display: none;
}

@media (max-width: tablet) {
  .mobileOnly {
    display: block;
  }
  .desktopOnly {
    display: none;
  }
}
