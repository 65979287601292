@value vars: "styles/theme/vars.module.css";
@value phone, tablet from vars;

.heading {
  font-style: normal;
  text-edge: cap;
  leading-trim: both;
}

.heading > span {
  color: var(--color-underscore);
  font-weight: 400;
}

.large {
  font-weight: 400;
  font-size: 64px;
  letter-spacing: -1.28px;
  text-transform: capitalize;
}

.large-42 {
  font-size: 42px;
  text-transform: capitalize;
}

.new-large-42 {
  font-size: 42px;
  text-transform: capitalize;
  font-weight: 400;
}

.medium,
.medium-32 {
  font-weight: 400;
  font-size: 30px;
  letter-spacing: -0.6px;
  text-transform: capitalize;
}

.medium-32 {
  font-size: 32px;
}

.medium-28 {
  font-size: 28px;
  font-weight: 300;
  letter-spacing: -0.56px;
}

.medium-24 {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.48px;
}

.medium-20 {
  font-size: 20px;
  font-weight: 400;
}

.body {
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.36px;
}

.small {
  font-weight: 400;
  font-size: 18px;
  letter-spacing: -0.36px;
}

.small-16 {
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.32px;
}

.small-14 {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.28px;
}

.eyerows {
  font-weight: 400;
  font-size: 24px;
  letter-spacing: 0.05em;

  text-transform: uppercase;
}

.line-height-large {
  line-height: 133%;
}

.line-height-regular {
  line-height: 120%;
}

.color-primary {
  color: var(--color-text);
}

.color-secondary {
  color: var(--color-action-2);
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

@media (max-width: tablet) {
  .large {
    font-size: 48px;
    letter-spacing: -0.96px;
  }

  .large-42 {
    font-size: 36px;
  }

  .new-large-42 {
    font-size: 32px;
  }

  .medium {
    font-size: 28px;
    letter-spacing: -0.56px;
  }

  .medium-32 {
    font-size: 20px;
    letter-spacing: -0.4px;
  }

  .body {
    font-size: 16px;
    letter-spacing: -0.32px;
  }
}

@media (max-width: phone) {
  .body {
    font-size: 16px;
    letter-spacing: -0.32px;
  }

  .eyerows {
    font-size: 16px;
  }
}
