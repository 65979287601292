.box {
  padding: 16px;
  border-radius: 24px;
  background-color: var(--color-grey-900);
  border: 1px solid rgba(0, 0, 0, 0);
  display: flex;
  gap: 24px;
  width: max-content;
  border: 1px solid var(--color-grey-800);
}

.navList {
  display: flex;
  flex-direction: column;
}

.linkItem {
  padding: 8px;
  border-radius: 8px;
  display: flex;
  gap: 12px;
  align-items: center;
  background-color: var(--color-grey-900);
}

.linkItem:hover {
  background-color: var(--color-grey-800);
}

.linkItemIcon {
  composes: iconWrapperBox from '/src/styles/sharedStyles.module.css';
}

.linkItemIcon svg {
  width: 24px;
  height: 24px;
}

.linkIteContent {
  display: flex;
  flex-direction: column;
}
.linkIteContentHeader {
  letter-spacing: -0.038px;
  color: var(--color-neutral-light);
}
.linkIteContentBody {
  letter-spacing: -0.011px;
  color: var(--color-grey-300);
}

.imageBox {
  width: 240px;
  min-height: 256px;
  height: 100%;
  border-radius: 24px;
  overflow: hidden;
}

.imageBox > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
