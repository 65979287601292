@value vars: "styles/theme/vars.module.css";
@value phone, tablet, tablet-min, container from vars;

.headerWrapper {
  margin-inline: 44px;
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  max-width: 1352px;
  height: 61px;
  width: 100%;
  top: 16px;
  margin: 0 auto;
}

.logoWrapper {
  width: 242px;
  transform: translateX(-30px);
}

.navWrapper {
  display: flex;
  align-items: center;
  gap: 32px;
  position: relative;
}

.headerRightPanel {
  display: flex;
  align-items: center;
  gap: 32px;
  border-radius: 32px;
  background-color: transparent;
  padding: 12px 24px;
  transition: all 500ms ease;

  border: 1px solid transparent;
}

.navigationBtn {
  padding: 8px 24px;
  height: auto;
  justify-self: flex-end;
  cursor: default;
  line-height: 21px;
}

.navText {
  letter-spacing: -0.038px;
}

.header {
  position: relative;
  z-index: 10;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: absolute;
  left: 0;
}

.navigation {
  position: relative;
  display: flex;
  align-items: center;
  gap: 44px;
  color: var(--color-grey-300);
  transition: all 500ms ease;
  margin-left: 166px;

  padding: 12px 32px;
}

.navigation span {
  cursor: default;
}

.navigation a {
  text-decoration: none;
}

.link {
  text-transform: capitalize;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  cursor: pointer;
}

.litepaper {
  position: relative;
}
.litepaper:hover {
  opacity: 1;
  color: var(--color-neutral-light);
}

.burgerMenu {
  display: none;

  width: 24px;
  height: 24px;

  cursor: pointer;
}

.burgerMenu svg {
  width: 24px;
  height: 24px;

  stroke: var(--color-text);
}

.stayTuned {
  white-space: nowrap;
}

/* secondary design ---------------------------- */

.secondaryListImg {
  position: absolute;
  left: -20px;
  z-index: -1;
}

.secondaryNavigation {
  background-color: var(--color-header-bg);
  padding: 12px 24px;
  border-radius: 32px;
  margin-left: 66px;
  z-index: 1;
}

.secondaryNavWrapper {
  gap: 0;
}

.secondaryRightPanel {
  border-radius: 32px;
  background-color: var(--color-header-bg);
  padding: 12px 24px;
  border: 1px solid var(--color-grey-800);
}

/* alterntive design  ---------------------------*/

.alternativeHeaderWrapper {
  padding: 20px 44px 0px 44px;

  width: 100%;
  margin-inline: auto;
}

/* alter classes */
/* used for old pages */
.ghostHeight {
  height: calc(var(--header-desktop-height-for-old-pages));
}

/* media queries ------------------------- */

@media (max-width: tablet) {
  .logoWrapper {
    width: 190px;
    transform: translateX(-23px);
  }

  .headerWrapper {
    padding: 0 16px;
    display: flex;
    height: 52px;
    align-items: center;
    margin-inline: 0px;
  }
  .navigation {
    display: none;
  }

  .headerRightPanel {
    display: none;
  }

  .burgerMenu {
    display: block;
  }

  /* used for old pages */
  .ignoredHeight {
    margin-bottom: calc(var(--header-mobile-height) * -1);
  }

  .ghostHeight {
    height: calc(var(--header-mobile-height));
  }
  /* ------------------- */
}

@keyframes slideFromLeft {
  from {
    opacity: 0;
    transform: translateX(-100%) rotate(-180deg);
  }
  to {
    opacity: 1;
    transform: translateX(0px) rotate(0deg);
  }
}
