@value vars: 'styles/theme/vars.module.css';
@value phone, tablet, container from vars;

.menuOpen {
  transform: translateX(0%);
  transition: transform var(--tran-duration-300) ease-in-out;
}

.menuClosed {
  transform: translateX(200%);
  transition: transform var(--tran-duration-300) ease-in-out;
}

.mobileMenuWrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 11;
  padding-bottom: 50px;

  width: 100vw;
  height: 100svh;
  background-color: var(--color-grey-900);
  overflow-y: auto;

  display: flex;
  flex-direction: column;
}

.headerBody {
  flex: 1;
  display: flex;
  gap: 80px;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: tablet) {
  .mobileMenuWrapper {
    display: none;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 16px 12px 16px;
  margin-bottom: 12px;
}

.header button {
  width: 24px;
  height: 24px;
}

/* logos */
.logos {
  display: flex;
  align-items: center;
}

.logos > svg:nth-child(1) {
  height: 28px;
  width: 107px;

  fill: var(--color-header-logo);
}

.logos > svg:nth-child(2) {
  margin-left: 10px;

  width: 92px;
  height: 23px;

  fill: var(--color-header-logo);
}

/* close icon */
.header > button > svg {
  width: 24px;
  height: 24px;

  stroke: var(--color-text);
}

.navigation a {
  text-decoration: none;
}

.navigation {
  width: 100%;

  background-color: var(--color-grey-900);
  z-index: 3;
}

.navigation > div {
  padding: 16px;
}
.navigation > div:last-child {
  border-bottom: none;
}

.link {
  text-transform: capitalize;

  /* SF/H5 */
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 150% */
  letter-spacing: 0.076px;
  cursor: pointer;
}

.expanderHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.expanderHeader svg {
  width: 24px;
  height: 24px;

  transform: rotate(0deg);
  fill: var(--color-text);
  transition: transform var(--tran-duration-300) ease-in-out;
}

.expanderHeader.active svg {
  transform: rotate(180deg);
}

.expanderItemList,
.expanderItemList * {
  opacity: 0;
  max-height: 0;
  pointer-events: none;

  transition: all var(--tran-duration-300) ease-in-out;
}

.expanderItemList.active,
.expanderItemList.active * {
  opacity: 1;
  max-height: 1050px;
  pointer-events: all;
}

.subHeadingItem {
  padding: 8px 8px 8px 24px;
  display: flex;
  gap: 12px;
  align-items: center;
  color: var(--color-text);
}
.subHeadingItem:last-child {
  padding-bottom: 16px;
}
.expanderItemList .lineDivider {
  margin-top: 16px;
}
.lineDivider {
  height: 1px;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(95, 88, 255, 0) -3.66%,
    #5f58ff 50.35%,
    rgba(95, 88, 255, 0) 105.46%
  );
}
.expanderHeader.active + .lineDivider {
  background: transparent;
}
.expanderHeader + .lineDivider {
  padding: 0;
  background: linear-gradient(
    90deg,
    rgba(104, 104, 104, 0) -4.8%,
    #686868 50.46%,
    rgba(104, 104, 104, 0) 106.85%
  );
}

.subHeadingItemTextWrapper {
  display: flex;
  flex-direction: column;
}

.subHeadingItem a {
  text-decoration: none;
  color: inherit;
}

.subHeadingItemHeader {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: -0.038px;
  color: inherit;
}

.subHeadingItemBody {
  color: var(--color-grey-300);

  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.011px;
}

.linkItemIcon {
  composes: iconWrapperBox from '/src/styles/sharedStyles.module.css';
}

/* footer section  ----*/
.footerSection {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
}

.btnWrapper > button {
  height: 40px;
  width: 164px;
  padding: 8px 24px;
}
