@value vars: "styles/theme/vars.module.css";
@value phone-middle, tablet from vars;

/* Paragraph font weights */
.regular {
  font-weight: 400;
}
.medium {
  font-weight: 500;
}
.bold {
  font-weight: 700;
}
.semibold {
  font-weight: 600;
}

/* paragraph */
.largeBody {
  font-size: 20px;
  line-height: 150%;
  letter-spacing: 0.076px;
}
.header {
  font-size: 44px;
  line-height: 66px;
  letter-spacing: 0.076px;
}
.subHeader {
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0.076px;
}
.body {
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.038px;
}
.smallBody {
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.011px;
}
.tinyBody {
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.008px;
}

/* MHeading styles */
.hero {
  font-size: 64px;
  line-height: 150%;
  font-weight: 700;
  letter-spacing: -0.32px;
  font-weight: 700;
}

.h1 {
  font-size: 56px;
  line-height: 150%;
  font-weight: 700;
}
.h2 {
  font-size: 44px;
  line-height: 150%;
  font-weight: 700;
  letter-spacing: 0.18px;
}
.h3 {
  font-size: 32px;
  line-height: 150%;
  font-weight: 700;
  letter-spacing: 0.131px;
}
.h4 {
  font-size: 24px;
  line-height: 150%;
  font-weight: 700;
  letter-spacing: 0.082px;
}
.h5 {
  font-size: 20px;
  line-height: 150%;
  font-weight: 700;
  letter-spacing: 0.076px;
}

.card {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: 0.082px;
}

@media (max-width: phone-middle) {
  .hero {
    font-size: 32px;
  }
  .h1 {
    font-size: 44px;
  }
  .h2 {
    font-size: 32px;
  }
  .h3 {
    font-size: 24px;
  }
  .h4 {
    font-size: 20px;
  }
  .h5 {
    font-size: 16px;
  }
  .largeBody {
    font-size: 16px;
    letter-spacing: -0.038px;
  }
}
