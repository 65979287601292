.container {
  position: relative;
  --desktop-margin-left: 39px;
  --triangle-half-width: 13px;
}

.container .child {
  display: none;
}

.container:hover .child {
  display: block;
}

.container:hover > div:first-of-type span {
  color: var(--color-neutral-light);
}

.ghostHeightWhenOffset {
  position: absolute;
  width: 100%;
  top: 100%;
}

.child {
  position: absolute;
  top: calc(100% + var(--child-offset-top));
  transform: translateX(
    calc(var(--parent-half-width) - var(--triangle-half-width) - var(--desktop-margin-left))
  );
}

.triangleWrapper {
  display: inline-block;
  position: relative;
  width: 28px;
  height: 14px;
  background-color: var(--color-grey-800);
  box-sizing: border-box;
  clip-path: polygon(50% 0, 0% 100%, 100% 100%);
  margin-left: calc(var(--desktop-margin-left));
  transform: translateY(5px);
}
.triangle {
  position: absolute;
  background-color: var(--color-grey-900);
  top: 1px; /* equal to border thickness */
  left: 1px; /* equal to border thickness */
  width: 26px; /* container height - (border thickness * 2) */
  height: 13px; /* container height - (border thickness * 2) */
  clip-path: polygon(50% 0, 0% 100%, 100% 100%);
}
