@font-face {
  font-family: 'Aetonik';
  src: url('/public/fonts/Aeonik/Aeonik-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aetonik';
  src: url('/public/fonts/Aeonik/Aeonik-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aetonik';
  src: url('/public/fonts/Aeonik/Aeonik-Light.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aetonik';
  src: url('/public/fonts/Aeonik/Aeonik-LightItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Aetonik';
  src: url('/public/fonts/Aeonik/Aeonik-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aetonik';
  src: url('/public/fonts/Aeonik/Aeonik-RegularItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}


/* SF pro */

@font-face {
  font-family: "SF Pro Display";
  src: url("/public/fonts/SFpro/SF-Pro-Display-Ultralight.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("/public/fonts/SFpro/SF-Pro-Display-Thin.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("/public/fonts/SFpro/SF-Pro-Display-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("/public/fonts/SFpro/SF-Pro-Display-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("/public/fonts/SFpro/SF-Pro-Display-Semibold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("/public/fonts/SFpro/SF-Pro-Display-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
