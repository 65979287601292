.themeSwitcher {
  display: flex;
  align-items: center;
  padding: 2px;
  column-gap: 12px;
  width: fit-content;

  border-radius: 30px;
  background-color: var(--color-theme-button-background);
  cursor: pointer;
}

.themeButton {
  position: relative;
  height: 33px;
  width: 33px;

  border: none;
  border-radius: 50%;
  background-color: var(--color-theme-button-background);
  cursor: pointer;
}

.themeButton svg {
  position: absolute;
  bottom: 3px;
  left: 3px;

  width: 26px;
  height: 26px;

  fill: var(--color-theme-button-text);
}

.selectedThemeButton {
  background-color: var(--color-white);
}

.selectedThemeButton svg {
  fill: var(--color-theme-button-selected);
  background-color: transparent;
}
