@value vars: 'styles/theme/vars.module.css';
@value phone from vars;

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  width: 100%;
  padding: 0 32px;
  color: var(--color-neutral-light);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.0024em;
  height: 48px;
  transition: all 0.2s ease;
}
.button:disabled {
  opacity: 0.5;
  cursor: initial;
}
.button:disabled:hover {
  background: var(--color-blue-500);
}

.contained {
  background: var(--color-blue-500);
}
.contained:hover {
  background: var(--color-blue-400);
}

.outlined {
  background: transparent;
  border: 2px solid var(--color-blue-500);
}
.outlined:hover {
  background: #5f58ff40;
}
.orange.button:disabled:hover {
  background: var(--color-orange-500);
}

.orange.contained {
  background: var(--color-orange-500);
}
.orange.contained:hover {
  background: var(--color-orange-400);
}

.orange.outlined {
  background: transparent;
  border: 2px solid var(--color-orange-500);
}
.orange.outlined:hover {
  background: var(--color-orange-100);
}

.outlined:disabled .contained:disabled {
  opacity: 0.5;
}

.loaderIconWrapper {
  width: 24px;
  height: 24px;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
