@value vars: 'styles/theme/vars.module.css';
@value phone, tablet, container from vars;

.toasterContainer {
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  z-index: 10; /* to show toasts on the front side when modal is opened*/
  width: 500px;
  background: transparent;
}

@media (max-width: phone) {
  .toasterContainer {
    width: 350px;
  }
}

.toaster {
  width: 100%;
  position: relative;
  padding: 0 20px;
  border-radius: 4px;

  display: flex;
  align-items: center;
  column-gap: 15px;
  min-height: 90px;

  background-color: var(--color-background);
  box-shadow: var(--shadow-primary);

  transition: transform var(--transition-toaster) ease-in-out;
  overflow: hidden;

  animation-name: var(--animation-type);
  animation-duration: var(--transition-toaster);
  animation-timing-function: ease-in-out;
}

/* toasts animation */
@keyframes reveal {
  0% {
    transform: translateX(var(--distance) px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes hide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(var(--distance) px);
  }
}

.toasterCountdown {
  position: absolute;
  bottom: 0;
  right: 0;

  height: 4px;
  width: 100%;
  border-radius: 0 0 4px 0;

  background-color: var(--color-toaster);

  animation: decreaseBar ease-in-out 1;
  animation-fill-mode: forwards;
  animation-duration: 5s;
}

@keyframes decreaseBar {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(470px, 0, 0);
  }
}

.toasterIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  width: 40px;
  height: 40px;
}

.toasterLoader {
  transform: scale(0.175);
  color: var(--color-toaster);
}

.toasterIcon svg {
  height: 16px;
  width: 16px;
  fill: var(--color-toaster);
}

.toasterContent {
  padding: 8px;
  max-width: calc(100% - 60px);
  width: 100%;
}

.toasterContent div {
  word-break: break-word;
}

.title {
  color: var(--color-toaster);
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 8px;
}

.message {
  font-weight: 500;
  font-size: 14px;
  color: var(--color-text);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
