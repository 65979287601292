.socialMedia {
  min-width: min-content;
  max-width: 100%;
  display: flex;
  gap: 24px;
  height: 24px;
}
.socialMedia > a {
  height: 24px;
}

.socialMedia svg {
  width: 24px;
  height: 24px;
  fill: var(--color-text);
}
.socialMedia a:last-child svg {
  margin-top: -2px;
}
.wrapper {
  position: relative;
}
.wrapper,
.wrapper svg,
.pulsation {
  border-radius: 50%;
}
.wrapper svg {
  z-index: 1;
  position: relative;
}
.pulsation {
  width: 29px;
  height: 29px;
  position: absolute;
  inset: 0;
  z-index: 0;

  left: -1px;
  top: -2px;

  animation-timing-function: ease-out;
}
.pulsation:nth-child(2) {
  transform: scale(1);
  animation: primaryScale 1s;
  background: rgba(248, 100, 18, 0.8);
}
.pulsation:nth-child(3) {
  animation: primaryPulse1 2s infinite;
  animation-delay: 0.8s;
}
.pulsation:nth-child(4) {
  animation: primaryPulse2 2s infinite;
  animation-delay: 1.6s;
}

@keyframes primaryScale {
  0% {
    transform: scale(0.1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes primaryPulse1 {
  0% {
    box-shadow: 0 0 0 0 rgba(248, 100, 18, 0.6);
  }

  100% {
    box-shadow: 0 0 0 18px rgba(248, 100, 18, 0);
  }
}
@keyframes primaryPulse2 {
  0% {
    box-shadow: 0 0 0 0 rgba(248, 100, 18, 0.6);
  }

  100% {
    box-shadow: 0 0 0 18px rgba(248, 100, 18, 0);
  }
}
